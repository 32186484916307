<template>
  <div class="project-bind-process">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="项目流程绑定" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          clearable
          v-model="searchData.companyId"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选择所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
        />
        <!-- 流程分类 -->
        <ml-select
          prop="businessType"
          placeholder="请选择流程分类"
          :options="typeOptions"
          label="流程分类:"
          keyName="key"
          labelName="value"
          valueName="key"
          v-model="searchData.businessType"
          clearable
          @change="searchFn"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ marginBottom: 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 添加按钮 -->
        <ml-button
          :style="{ marginBottom: 0 }"
          submitText="项目流程绑定"
          submitIcon="el-icon-plus"
          :showCancel="false"
          @click-submit="clickAdd"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tableData.data" style="width: 100%" :height="tabberHeight(330)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tableData.page - 1) * tableData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="阿米吧" prop="companyName" min-width="10%" />
        <el-table-column
          align="center"
          label="流程名称"
          prop="processName"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="项目名称"
          prop="projectName"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="流程分类"
          prop="businessTypeStr"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="流程描述"
          prop="processDescribe"
          min-width="30%"
          show-overflow-tooltip
        />

        <el-table-column align="center" label="修改时间" prop="ts" min-width="15%" />
        <el-table-column align="center" label="操作" priop="processId" min-width="20%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tableData.total"
        :page="tableData.page"
        v-model="tableData.page"
        :size="tableData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除账户提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
    <bind-dialog
      v-if="showDialog"
      ref="bindDialogRef"
      :id="id"
      @close="closeHandle"
      @success="successHandle"
    ></bind-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch, nextTick } from 'vue'
import { tabberHeight, getTabberData, clearMemoryPageFn } from '@/utils'
import { useStore } from 'vuex'
import bindDialog from './components/bindDialog.vue'
export default {
  name: 'projectBindProcess',
  components: {
    bindDialog
  },
  setup() {
    const { commit, dispatch, getters } = useStore()
    const getMemoryPage = computed(() => getters.getMemoryPage)
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      businessType: getMemoryPage.value.searchData.businessType || ''
    })
    // 单位配置
    const unitOptions = ref([])
    // 项目配置
    const projectData = ref([])

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tableData.page === 1 ? getProjectProcessTableData() : (tableData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tableData.page === 1 ? getProjectProcessTableData() : (tableData.page = 1)
    }

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    watch(
      () => searchData.companyId,
      async newvalue => {
        projectData.value = []
        searchData.projectId = ''
        if (newvalue) {
          await getProjectData(newvalue)
        }
      }
    )

    // 流程分类
    const typeOptions = ref([])
    const getProcessTypeAction = () => {
      dispatch('fetchGetDictChildren', { dictType: 'process_type' }).then(data => {
        if (data && data.code === 200) {
          typeOptions.value = data.data || []
          getProjectProcessTableData()
        }
      })
    }

    // 单位配置
    const tableData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })
    //分页事件
    watch([() => tableData.page, () => tableData.size], () => {
      getProjectProcessTableData()
    })
    const currentChange = page => {
      tableData.page = page
    }
    const sizeChange = size => {
      tableData.size = size
    }

    // 获取table列表数据
    const getProjectProcessTableData = async (
      params = { pageIndex: tableData.page, pageSize: tableData.size }
    ) => {
      const searchDataParams = searchData
      const { data } = await getTabberData(
        dispatch,
        'fetchProjectProcessPage',
        params,
        searchDataParams
      )
      let list = data.records
      list.forEach(d => {
        let type = typeOptions.value.find(k => k.key === d.businessType)
        d.businessTypeStr = (type && type.value) || ''
      })
      tableData.data = list || []
      tableData.total = data.total || 0
    }
    let showDialog = ref(false)
    const bindDialogRef = ref()
    let id = ref('')
    // 添加项目流程绑定
    const clickAdd = () => {
      id.value = ''
      showDialog.value = true
      nextTick(() => {
        bindDialogRef.value.bindDialogRef.showDialog = true
      })
    }
    const clickEdit = (index, row) => {
      id.value = row.id
      showDialog.value = true
      nextTick(() => {
        bindDialogRef.value.bindDialogRef.showDialog = true
      })
    }

    const closeHandle = () => {
      console.log(632)
      id.value = ''
      showDialog.value = false
    }
    const successHandle = () => {
      closeHandle()
      getProjectProcessTableData()
    }

    // 删除账户
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      id: '',
      content: '确定删除该项目流程绑定关系？'
    })

    const clickDelete = (index, row) => {
      deleteUserData.id = row.id
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { id } = deleteUserData
      dispatch('fetchDeleteProjectProcess', { id }).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          searchFn()
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      getProcessTypeAction()
    })
    return {
      searchDataRef,
      searchData,
      getProjectProcessTableData,
      unitOptions,
      tableData,
      projectData,
      getProjectData,
      typeOptions,
      getProcessTypeAction,
      tabberHeight,
      resetSearchData,
      searchFn,
      clickAdd,
      currentChange,
      sizeChange,
      showDialog,
      closeHandle,
      successHandle,
      id,
      clickEdit,
      clickDelete,
      deleteUserDialogRef,
      deleteUserData,
      submitDeleteUser,
      bindDialogRef
    }
  }
}
</script>
