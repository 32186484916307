<template>
  <ml-dialog
    width="600px"
    ref="bindDialogRef"
    title="项目流程绑定"
    :btnLoading="btnLoading"
    @click-submit="submitBindHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <ml-form
        style="width: 100%; padding: 0 20px"
        labelWidth="100px"
        :model="bindDialogData"
        ref="bindDialogFormRef"
        :rules="bindDialogFormRules"
      >
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          :filterable="true"
          v-model="bindDialogData.companyId"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选择项目"
          :options="projectData"
          label="项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          :filterable="true"
          v-model="bindDialogData.projectId"
        />
        <!-- 流程 -->
        <ml-select
          prop="definitionId"
          placeholder="请选择流程"
          :options="processData"
          label="流程:"
          keyName="definitionId"
          labelName="processName"
          valueName="definitionId"
          v-model="bindDialogData.definitionId"
          :filterable="true"
          @change="definitionChange"
        />
        <!-- 流程分类 -->
        <ml-select
          prop="businessType"
          placeholder="请选择业务分类"
          :options="typeOptions"
          label="业务分类:"
          keyName="key"
          labelName="value"
          valueName="key"
          v-model="bindDialogData.businessType"
          clearable
        />
        <ml-input-number
          prop="orderIndex"
          placeholder="请输入排序"
          label="排序:"
          :min="0"
          :precision="0"
          v-model="bindDialogData.orderIndex"
        />
        <!-- 描述 -->
        <ml-input
          prop="propertySn"
          placeholder="请输入描述"
          type="textarea"
          :rows="3"
          label="描述:"
          v-model="bindDialogData.processDescribe"
          :maxlength="200"
        />
      </ml-form>
    </template>
  </ml-dialog>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { getTabberData } from '@/utils'
export default {
  name: 'bindDialog',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  emits: ['close', 'success'],
  setup(props, context) {
    const { commit, dispatch, getters } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    let btnLoading = ref(false)
    const bindDialogRef = ref()
    const bindDialogFormRef = ref()
    const bindDialogData = reactive({
      id: '',
      projectId: '',
      definitionId: '',
      processKey: '',
      businessType: '',
      companyId: '',
      orderIndex: 0,
      processDescribe: ''
    })
    const bindDialogFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择项目', trigger: 'blur' }],
      definitionId: [{ required: true, message: '请选择流程', trigger: 'blur' }],
      businessType: [{ required: true, message: '请选择业务分类', trigger: 'blur' }],
      orderIndex: [{ required: true, message: '请输入排序', trigger: 'blur' }]
    }
    // 单位配置
    const unitOptions = ref([])
    // 项目配置
    const projectData = ref([])
    // 流程
    const processData = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
      const item = projectData.value.find(d => d.projectId === bindDialogData.projectId)
      if (!item) {
        bindDialogData.projectId = ''
      }
    }
    watch(
      () => bindDialogData.companyId,
      async newValue => {
        projectData.value = []
        if (newValue) {
          await getProjectData(newValue)
        }
      }
    )
    // 流程分类
    const typeOptions = ref([])
    const getProcessTypeAction = () => {
      dispatch('fetchGetDictChildren', { dictType: 'process_type' }).then(data => {
        if (data && data.code === 200) {
          typeOptions.value = data.data || []
        }
      })
    }
    const getProcessData = () => {
      dispatch('fetchDefinitionList', { processName: '' }).then(res => {
        if (res.code === 200) {
          processData.value = res.data
        }
      })
    }

    const definitionChange = value => {
      if (value) {
        const item = processData.value.find(d => d.definitionId === value)
        bindDialogData.processKey = (item && item.processKey) || ''
      } else {
        bindDialogData.processKey = ''
      }
    }

    const getDetailData = async () => {
      const { data } = await getTabberData(dispatch, 'fetchProjectProcessInfo', { id: props.id })
      bindDialogData.id = data.id
      bindDialogData.projectId = data.projectId
      bindDialogData.definitionId = data.definitionId
      bindDialogData.processKey = data.processKey
      bindDialogData.businessType = data.businessType
      bindDialogData.companyId = data.companyId
      bindDialogData.orderIndex = data.orderIndex
      bindDialogData.processDescribe = data.processDescribe
    }

    const submitBindHandle = () => {
      btnLoading.value = true
      bindDialogFormRef.value.CustomFormRef.validate().then(() => {
        dispatch('fetchInsetProjectProcess', bindDialogData).then(res => {
          if (res && res.code === 200) {
            commit('setError', {
              status: true,
              title: res.message,
              message: '绑定成功...',
              type: 'success'
            })
            context.emit('success')
          }
        })
      })
    }
    const closeHandle = () => {
      context.emit('close')
    }

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      getProcessTypeAction()
      getProjectData('')
      getProcessData()
      if (props.id) {
        await getDetailData()
      }
    })
    return {
      bindDialogRef,
      bindDialogFormRef,
      bindDialogFormRules,
      btnLoading,
      bindDialogData,
      unitOptions,
      getProcessTypeAction,
      typeOptions,
      processData,
      projectData,
      submitBindHandle,
      closeHandle,
      getDetailData,
      getProcessData,
      definitionChange
    }
  }
}
</script>
